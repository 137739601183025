import { Page, PageStatus } from '../../../types/graphql';
import { campaign as baseCampaign } from './campaign';

interface Options {
  status?: PageStatus;
  cursor?: number;
  numberOfPages?: number;
  title?: string;
  hasNextPage?: boolean;
  campaign?: RecursivePartial<Page>;
}

export const charity = ({
  status = PageStatus.Active,
  cursor = 0,
  numberOfPages = 6,
  title = 'London Marathon',
  hasNextPage = true,
  campaign,
}: Options) => ({
  __typename: 'Charity',
  id: 1,
  children: {
    __typename: 'PageRelationshipConnection',
    edges: [...Array(numberOfPages)].map((_, index) => {
      const id = `${status}-${cursor}-${index}`;

      return {
        __typename: 'PageRelationshipEdge',
        cursor: id,
        node: baseCampaign({ campaign, id, title, status }),
      };
    }),
    pageInfo: {
      __typename: 'PageInfo',
      hasNextPage,
      endCursor: cursor + 1,
    },
  },
});
