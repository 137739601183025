import React, { FunctionComponent } from 'react';

import ErrorPage from '@justgiving/error-page';

import { isCustomError } from '../ErrorBoundary/errors';

interface ErrorPageProps {
  error?: Error;
}

const ErrorPageWrapper: FunctionComponent<ErrorPageProps> = ({ error }) => {
  const errorCode = isCustomError(error) ? error.code : 500;

  return (
    <div className="jg-space-phmd@lg jg-space-pvmd">
      <ErrorPage errorMessage={error?.message ?? ''} status={errorCode} />
    </div>
  );
};

export default ErrorPageWrapper;
