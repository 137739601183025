import React, { createContext, useContext, useState } from 'react';

import { PageType } from '../../../types/graphql';
import { parsePath } from '../../utils/parsePath';

export type PathInfo = {
  pageType: PageType;
  pageSlug: string;
  adminBasePath: string;
  pageBasePath: string;
};

type PathInfoContextData = {
  pathInfo: PathInfo | undefined;
  setPathInfo: (p: PathInfo | undefined) => void;
};

const PathInfoContext = createContext<PathInfoContextData | undefined>(
  undefined
);
PathInfoContext.displayName = 'PathInfoContext';

interface PathInfoProviderProps {
  path?: string;
}

export const PathInfoProvider: React.FunctionComponent<PathInfoProviderProps> =
  ({ path = window.location.pathname, children }) => {
    const [pathInfo, setPathInfo] = useState<PathInfo | undefined>(
      parsePath(path)
    );

    const contextData: PathInfoContextData = {
      pathInfo,
      setPathInfo: (a) => setPathInfo(a),
    };

    return (
      <PathInfoContext.Provider value={contextData}>
        {contextData.pathInfo && children}
      </PathInfoContext.Provider>
    );
  };

export const usePathInfo = () => {
  const context = useContext(PathInfoContext);

  if (!context || !context.pathInfo) {
    throw new Error('usePathInfo must be used within a PathInfoProvider');
  }

  return context.pathInfo;
};

export const useUpdatePathInfo = () => {
  const context = useContext(PathInfoContext);

  if (!context || !context.pathInfo) {
    throw new Error('usePathInfo must be used within a PathInfoProvider');
  }

  return (pathProvider: () => string) => {
    // this will stop the react-router provider from rendering until the update is complete
    context.setPathInfo(undefined);

    const path = pathProvider();

    context.setPathInfo(parsePath(path));
  };
};
