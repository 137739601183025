import React, { createContext, useContext } from 'react';

import Loading from '@justgiving/loading';

import {
  GetCorePageDataQuery,
  GetLoggedInCorePageDataQuery,
  Page,
  PageType,
} from '../../../types/graphql';
import { CustomError } from '../../components/ErrorBoundary/errors';
import { usePageQuery } from '../../hooks/usePageQuery';
import { usePathInfo } from '../../hooks/usePathInfo';
import {
  GetCorePageData,
  GetLoggedInCorePageData,
} from '../../queries/GetCorePageData.gql';

export type CorePageData = Required<
  Pick<
    Page,
    | 'id'
    | 'legacyId'
    | 'type'
    | 'slug'
    | 'title'
    | 'url'
    | 'owner'
    | 'supportsGiftAid'
    | 'product'
    | 'externalId'
  >
>;

function isLoggedInQuery(query: any): query is GetLoggedInCorePageDataQuery {
  return query?.viewer;
}

const CorePageDataContext = createContext<CorePageData | undefined>(undefined);
CorePageDataContext.displayName = 'CorePageDataContext';

export const CorePageDataProvider: React.FunctionComponent = ({ children }) => {
  const { pageType: type } = usePathInfo();

  const { loading, data } = usePageQuery<
    GetCorePageDataQuery | GetLoggedInCorePageDataQuery
  >(type === PageType.OnePage ? GetLoggedInCorePageData : GetCorePageData);

  if (loading) return <Loading />;

  const page = isLoggedInQuery(data) ? data?.viewer?.page : data?.page;
  const url = page?.url;
  const id = page?.legacyId || page?.id;

  if (!url || !id) {
    throw new CustomError({ message: 'Not found', code: 404 });
  }

  return (
    <CorePageDataContext.Provider value={page as CorePageData}>
      {children}
    </CorePageDataContext.Provider>
  );
};

export const useCorePageData = () => {
  const context = useContext(CorePageDataContext);
  if (context === undefined) {
    throw new Error(
      'useCorePageData must be used within a CorePageDataProvider'
    );
  }
  return context;
};
