import { ApolloClient, HttpLink, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { buildApolloCache } from './cache';
import auth from './auth';
import config from './config';
import { captureException, captureMessage, withScope } from '@sentry/react';

const fetchWithTokenRefresh = async (
  input: RequestInfo,
  init?: RequestInit
) => {
  if (auth.isUserLoggedIn()) {
    try {
      await auth.refreshAccessTokenIfExpired();
    } catch (error) {
      captureException(error);
    }
  }

  return fetch(input, init);
};

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation }) => {
    withScope((scope) => {
      scope.setTransactionName(operation.operationName);
      scope.setContext('ApolloGraphQLOperation', {
        operationName: operation.operationName,
        variables: operation.variables,
        extensions: operation.extensions,
      });

      graphQLErrors?.forEach((error) => {
        captureMessage(error.message, {
          level: 'error',
          fingerprint: ['{{ default }}', '{{ transaction }}'],
          contexts: {
            apolloGraphQLError: {
              error,
              message: error.message,
              extensions: error.extensions,
            },
          },
        });

        console.log(
          `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`
        );
      });

      if (networkError) {
        captureMessage(networkError.message, {
          level: 'warning',
          contexts: {
            apolloNetworkError: {
              error: networkError,
              extensions: (networkError as any).extensions,
            },
          },
        });

        console.log(`[Network error]: ${networkError}`);
      }
    });
  }
);

export const httpLink = new HttpLink({
  uri: config.graphqlUrl,
  fetch: fetchWithTokenRefresh,
  credentials: 'include',
  fetchOptions: {
    mode: 'cors',
    'apollographql-client-name': 'JG.Pages.Admin',
  },
});

export const buildClient = () =>
  new ApolloClient({
    name: 'JG.Pages.Admin',
    link: ApolloLink.from([errorLink, httpLink]),
    cache: buildApolloCache(),
  });

export const client = buildClient();
