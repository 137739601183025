import { useQuery } from '@apollo/client';
import {
  GetCharityIsApprovedQuery,
  GetCharityIsApprovedQueryVariables,
} from './../../types/graphql';
import { getCharityGroupTypeExternalId } from '../auth';
import GetCharityIsApproved from '../../src/queries/GetCharityIsApproved.gql';

export function useCharity() {
  const charityId = getCharityGroupTypeExternalId();
  const { data, ...rest } = useQuery<
    GetCharityIsApprovedQuery,
    GetCharityIsApprovedQueryVariables
  >(GetCharityIsApproved, {
    variables: {
      charityId,
    },
    errorPolicy: 'all',
  });

  const { charity } = data ?? {};

  return {
    data: {
      legacyId: charity?.legacyId ?? undefined,
      externalId: charityId,
      isApproved: charity?.isApproved ?? undefined,
    },
    ...rest,
  };
}

export type MappedCharity = ReturnType<typeof useCharity>['data'];
