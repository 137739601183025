import { graphql } from 'msw';

import {
  CreateTimelineEntryMutation,
  CreateTimelineEntryMutationVariables,
  GetAvailableSlugsQuery,
  GetAvailableSlugsQueryVariables,
  GetFrpEditPageFormQuery,
  GetFrpEditPageFormQueryVariables,
  GetLoggedInCorePageDataQuery,
  GetLoggedInCorePageDataQueryVariables,
  GetOfflineDonationsQuery,
  GetOfflineDonationsQueryVariables,
  LinkPageToCampaignMutation,
  LinkPageToCampaignMutationVariables,
  PageSettingsQuery,
  PageSettingsQueryVariables,
  PageStatus,
  PageType,
  StoryType,
  UpdatePageCoverMutation,
  UpdatePageCoverMutationVariables,
  UpdatePageGalleryMutation,
  UpdatePageGalleryMutationVariables,
} from '../../types/graphql';
import { charity } from './data/charity';
import { getFrpEditPage, page, PROXY_PAGE_SLUG } from './data/page';
import { campaign, getDates, getStatus } from './data/campaign';
import { parseISO } from 'date-fns';
import { search } from './data/search';

export const handlers = [
  graphql.query<GetFrpEditPageFormQuery, GetFrpEditPageFormQueryVariables>(
    'GetFRPEditPageForm',
    (req, res, ctx) => {
      const { slug } = req.variables;

      if (slug === PROXY_PAGE_SLUG) {
        return res(
          ctx.data({
            viewer: {
              id: 'user-id',
              __typename: 'User',
              page: getFrpEditPage({
                slug,
                type: PageType.Fundraising,
                storyWithType: {
                  type: StoryType.Html,
                  content: '<p>Test story</p>',
                },
              }),
            },
          })
        );
      }

      return res(
        ctx.data({
          viewer: {
            id: 'user-id',
            __typename: 'User',
            page: getFrpEditPage({ slug }),
          },
        })
      );
    }
  ),
  graphql.query<GetAvailableSlugsQuery, GetAvailableSlugsQueryVariables>(
    'GetAvailableSlugs',
    (req, res, ctx) => {
      return res(
        ctx.data({
          availableSlugs: {
            slugs: ['available-slug-1', 'available-slug-2'],
          },
        })
      );
    }
  ),
  graphql.mutation<
    UpdatePageGalleryMutation,
    UpdatePageGalleryMutationVariables
  >('UpdatePageGallery', (req, res, ctx) => {
    return res(
      ctx.data({
        updatePageGallery: {
          media: [
            {
              id: 'media-id',
            },
          ],
        },
      })
    );
  }),
  graphql.mutation<
    LinkPageToCampaignMutation,
    LinkPageToCampaignMutationVariables
  >('LinkPageToCampaign', (req, res, ctx) => {
    return res(
      ctx.data({
        linkPageToCampaign: true,
      })
    );
  }),
  graphql.mutation<UpdatePageCoverMutation, UpdatePageCoverMutationVariables>(
    'UpdatePageCover',
    (req, res, ctx) => {
      return res(
        ctx.data({
          updatePageCover: {
            cover: {
              id: 'cover-id',
            },
          },
        })
      );
    }
  ),
  graphql.query<
    GetLoggedInCorePageDataQuery,
    GetLoggedInCorePageDataQueryVariables
  >('GetLoggedInCorePageData', (req, res, ctx) => {
    const { slug } = req.variables;

    if (slug === 'page/giftaid-disabled') {
      return res(
        ctx.data({
          viewer: {
            __typename: 'User',
            id: 'viewer-id',
            page: {
              ...page,
              supportsGiftAid: false,
            },
          },
        })
      );
    }

    return res(
      ctx.data({
        viewer: {
          __typename: 'User',
          id: 'viewer-id',
          page,
        },
      })
    );
  }),
  graphql.query<PageSettingsQuery, PageSettingsQueryVariables>(
    'PageSettings',
    (req, res, ctx) => {
      return res(
        ctx.data({
          viewer: {
            id: 'viewer-id',
            page: {
              ...page,
              relationships: {
                teams: { nodes: [] },
                beneficiaries: {
                  nodes: [
                    {
                      supportsGiftAid: true,
                    },
                  ],
                },
              },
            },
          },
        })
      );
    }
  ),
  graphql.mutation<
    CreateTimelineEntryMutation,
    CreateTimelineEntryMutationVariables
  >('CreateTimelineEntry', (req, res, ctx) => {
    return res(
      ctx.data({
        createTimelineEntry: {
          id: 'created-timeline-id',
        },
      })
    );
  }),
  graphql.mutation<
    LinkPageToCampaignMutation,
    LinkPageToCampaignMutationVariables
  >('LinkPageToCampaign', (req, res, ctx) => {
    return res(
      ctx.data({
        linkPageToCampaign: true,
      })
    );
  }),
  graphql.query<GetOfflineDonationsQuery, GetOfflineDonationsQueryVariables>(
    'GetOfflineDonations',
    (req, res, ctx) => {
      return res(
        // AI generated code
        ctx.data({
          viewer: {
            id: 'viewer-id',
            page: {
              id: 'page-id',
              legacyId: 'page-legacy-id',
              donationSummary: {
                offlineAmount: {
                  currencyCode: 'GBP',
                  value: 1000,
                },
              },
              offlineDonations: {
                pageInfo: {
                  hasNextPage: false,
                  endCursor: null,
                  hasPreviousPage: false,
                },
                totalCount: 1,
                edges: [
                  {
                    node: {
                      legacyId: 'offline-donation-id',
                      id: 'offline-donation-id',
                      amount: 1000,
                      currency: 'GBP',
                      displayName: 'Offline donation',
                      dateCreated: '2023-03-02T10:16:39.799Z',
                      __typename: 'OfflineDonation',
                    },
                    __typename: 'OfflineDonationEdge',
                  },
                ],
                __typename: 'OfflineDonationConnection',
              },
            },
          },
        })
      );
    }
  ),
];

const endpoints: MockEndpoint[] = [
  {
    status: 'OK',
    client: {
      method: 'query',
      documentName: 'GetCharityChildren',
    },
    response: ([req]) => {
      const {
        statuses: [status = PageStatus.Active],
        after = 0,
        first = 6,
      } = req.variables;

      return {
        charity: charity({ status, cursor: after, numberOfPages: first }),
      };
    },
  },
  {
    status: 'OK',
    client: {
      method: 'mutation',
      documentName: 'UpdatePage',
    },
    response: ([req]) => {
      const { id, type, expiryDate } = req.variables.input;

      const status = getStatus(parseISO(expiryDate));

      switch (type) {
        case PageType.Campaign:
          return {
            updatePage: {
              page: {
                ...getDates(status),
                id,
                legacyId: id,
                status,
              },
            },
          };
        case PageType.Fundraising:
        default:
          return {
            updatePage: {
              page: {
                id: `PAGE:${type}:${id}`,
                legacyId: id,
                status: PageStatus.Active,
                launchDate: null,
                endDate: null,
              },
            },
          };
      }
    },
  },
  {
    status: 'OK',
    client: {
      method: 'query',
      documentName: 'Campaign',
    },
    response: ([req]) => {
      const { id } = req.variables;

      return {
        page: campaign({ id, status: PageStatus.Active }),
      };
    },
  },
  {
    status: 'OK',
    client: {
      method: 'mutation',
      documentName: 'CancelPage',
    },
    response: ([req]) => {
      const { id } = req.variables.input;

      return {
        cancelPage: {
          page: {
            id,
            legacyId: id,
          },
        },
      };
    },
  },
  {
    status: 'OK',
    client: {
      method: 'mutation',
      documentName: 'addOfflineDonation',
    },
    response: ([req]) => {
      const { amount, currency } = req.variables.input;

      return {
        addOfflineDonation: {
          id: 'T2ZmbGluZURvbmF0aW9uOjZlMDU1YWZmLTI1YzUtNGRhNy05ODAyLWE5N2I3MzM0ZjljZg==',
          amount,
          currency,
          displayName: 'Campaign admin offline donation',
          legacyId: '6e055aff-25c5-4da7-9802-a97b7334f9cf',
          dateCreated: '2023-03-02T10:16:39.799Z',
          __typename: 'OfflineDonation',
        },
      };
    },
  },
  {
    status: 'OK',
    client: {
      method: 'mutation',
      documentName: 'editOfflineDonation',
    },
    response: ([req]) => {
      const { amount, currency, offlineDonationId } = req.variables.input;

      return {
        editOfflineDonation: {
          id: 'T2ZmbGluZURvbmF0aW9uOjM3ZTBiNjcwLTZhMGQtNDllMS1iN2FhLWFlOTYxYjk5NmVhZQ==',
          amount,
          currency,
          displayName: 'Campaign admin offline donation',
          legacyId: offlineDonationId,
          dateCreated: '2023-04-02T10:16:39.799Z',
          __typename: 'OfflineDonation',
        },
      };
    },
  },
  {
    status: 'OK',
    client: {
      method: 'query',
      documentName: 'GetPageId',
    },
    response: () => {
      return {
        page: {
          id: 'UGFnZTpGVU5EUkFJU0lORzoyMmNjYmMyOS1kNDBjLTQ4YjQtOGIxNi1kMzA5YThmY2IwNjU=',
          legacyId: '22ccbc29-d40c-48b4-8b16-d309a8fcb065',
          __typename: 'Page',
        },
      };
    },
  },
  {
    status: 'OK',
    client: {
      method: 'mutation',
      documentName: 'AddPageRelationships',
    },
    response: ([req]) => {
      const { id } = req.variables.input;

      return {
        addPageRelationships: {
          page: {
            id: 'UGFnZTpPTkVfUEFHRToyMmNjYmMyOS1kNDBjLTQ4YjQtOGIxNi1kMzA5YThmY2IwNjU=',
            legacyId: id,
            __typename: 'PageWithId',
          },
          __typename: 'PageRelationshipsPayload',
        },
      };
    },
  },
  {
    status: 'OK',
    client: {
      method: 'query',
      documentName: 'Search',
    },
    response: ([req]) => {
      const {
        query = '',
        pagination: { first = 6, after = 0 },
        filters: {
          page: {
            statuses: [status = PageStatus.Active],
          },
        },
      } = req.variables;

      const searchResults = search({
        status,
        cursor: after,
        numberOfPages: first,
        withRef: false,
      });

      return {
        search: {
          ...searchResults,
          edges: searchResults.edges.filter((edge) => {
            if (query && 'title' in edge.node) {
              return edge.node.title.includes(query);
            }
            return true;
          }),
        },
      };
    },
  },
];

type Client = { method: 'query' | 'mutation'; documentName: string };
type Response =
  | Record<string, any>
  | ((
      resolverParameters: GraphQLQueryResolverParameters
    ) => Record<string, string>);

export interface MockEndpoint {
  status: 'OK' | 'ERROR';
  passthrough?: boolean;
  delay?: number;
  response?: Response;
  queryParam?: string;
  client: Client;
}

export function mockEndpoint(options: MockEndpoint) {
  const requestHandler = requestHandlerBuilder(options);

  return graphql[options.client.method](
    options.client.documentName,
    requestHandler
  );
}

type GraphQLQueryResolver = Parameters<typeof graphql.query>[1];
type GraphQLQueryResolverParameters = Parameters<GraphQLQueryResolver>;

const requestHandlerBuilder: (options: MockEndpoint) => GraphQLQueryResolver =
  (options) => (req, res, ctx) => {
    // Send the request as-is and ignore mocking
    if (options.passthrough) return req.passthrough();

    const response =
      typeof options.response === 'function'
        ? options.response([req, res, ctx])
        : options.response;

    if (options.status === 'ERROR') {
      return res(
        ctx.status(500),
        ctx.data({
          message: `internal server error`,
        })
      );
    }

    if (options.response) {
      return res(ctx.data(response));
    } else {
      return res();
    }
  };

export default () => [...handlers, ...endpoints.map(mockEndpoint)];
