import '@justgiving/loading/src/Loading.scss';
import '@justgiving/carepack-typography';
import '@justgiving/carepack-core';

import React, { FunctionComponent, Suspense, lazy } from 'react';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter } from 'react-router-dom';

import Loading from '@justgiving/loading';
import { PageComponentsProvider } from '@justgiving/page-components';
import { TrackingProvider } from '@justgiving/react-tracking';
import { ToastProvider } from '@justgiving/toast';

import { PageType } from '../../../types/graphql';
import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { AuthProvider, Permissions } from '../../context/Auth';
import { CorePageDataProvider } from '../../context/CorePageData';
import { PathInfoProvider } from '../../context/PathInfo';
import { usePathInfo } from '../../hooks/usePathInfo';
import { CharityAuthoriser } from '../../components/Authoriser/CharityAuthoriser';

import './App.scss';
import { OptimizelyProvider } from '../../components/OptimizelyProvider/OptimizelyProvider';

const analytics = {
  track: (data: Record<string, any>) => {
    window.dataLayer.push(data);
  },
};

const CrowdfundingRoot = lazy(
  () =>
    import(
      /* webpackChunkName: "Crowdfunding" */ '../Crowdfunding/CrowdfundingRoot'
    )
);
const FundraisingRoot = lazy(
  () =>
    import(
      /* webpackChunkName: "Fundraising" */ '../Fundraising/FundraisingRoot'
    )
);
const CampaignRoot = lazy(
  () => import(/* webpackChunkName: "Campaign" */ '../Campaign/CampaignRoot')
);

const Auth: FunctionComponent = ({ children }) => {
  const { pageType } = usePathInfo();

  switch (pageType) {
    case PageType.Campaign:
      return (
        <CharityAuthoriser>
          <AuthProvider permissions={[Permissions.IsCampaignAdmin]}>
            {children}
          </AuthProvider>
        </CharityAuthoriser>
      );
    case PageType.OnePage:
    case PageType.Crowdfunding:
    default:
      return (
        <AuthProvider permissions={[Permissions.IsPageOwner]}>
          {children}
        </AuthProvider>
      );
  }
};

const RootOrNotFound: React.FunctionComponent = () => {
  const { pageType } = usePathInfo();

  switch (pageType) {
    case PageType.Campaign:
      return <CampaignRoot />;
    case PageType.Crowdfunding:
      return (
        <CorePageDataProvider>
          <CrowdfundingRoot />
        </CorePageDataProvider>
      );
    case PageType.OnePage:
    default:
      return (
        <CorePageDataProvider>
          <FundraisingRoot />
        </CorePageDataProvider>
      );
  }
};

const RouterWithBasename: FunctionComponent = ({ children }) => {
  const { adminBasePath } = usePathInfo();

  return <BrowserRouter basename={adminBasePath}>{children}</BrowserRouter>;
};

const App: FunctionComponent = () => (
  <ErrorBoundary>
    <PathInfoProvider>
      <Auth>
        <TrackingProvider analytics={analytics}>
          <ToastProvider>
            <OptimizelyProvider>
              <Suspense fallback={<Loading />}>
                <RouterWithBasename>
                  <PageComponentsProvider className="jg-display-flex jg-flex-g1">
                    <RootOrNotFound />
                  </PageComponentsProvider>
                </RouterWithBasename>
              </Suspense>
            </OptimizelyProvider>
          </ToastProvider>
        </TrackingProvider>
      </Auth>
    </PathInfoProvider>
  </ErrorBoundary>
);

export default hot(App);
