import { Auth, createAuth } from '@justgiving/auth';

import { gotoUrl } from '../src/utils/url';
import config from './config';
import { RedirectToLoginError } from './components/ErrorBoundary/errors';

const previousAuth = createAuth({
  documentOrReq: document,
  authCookieName: config.auth.bearerCookieName,
  activeGroupCookieName: config.auth.activeGroupCookieName,
  refreshAccessTokenUrl: config.auth.refreshAccessTokenUrl,
});

export interface AuthWithHelpers extends Auth {
  isLoggedIn(): boolean;
}

const isLoggedIn = () => previousAuth.isUserLoggedIn();

const newAuth: AuthWithHelpers = {
  ...previousAuth,
  isLoggedIn,
};

export function redirectToSSO(returnUrl: string = window.location.href) {
  gotoUrl(config.loginUrl + encodeURIComponent(returnUrl));
}

export function getCharityGroupTypeExternalId() {
  const activePermission = newAuth.getActivePermission('charity');

  if (!activePermission?.groupTypeExternalId) {
    throw new RedirectToLoginError({
      loginUrl: `${config.charitySSOUrl}${encodeURIComponent(
        window.location.href
      )}`,
    });
  }

  return activePermission.groupTypeExternalId;
}

export default newAuth;
