import React, { ReactNode } from 'react';
import { useCharity } from '../../hooks/useCharity';
import Loading from '@justgiving/loading';
import config from '../../config';

interface AuthoriserProps {
  children?: ReactNode;
}

const CharityAuthoriser = ({ children }: AuthoriserProps) => {
  const { data: charity, loading } = useCharity();
  if (loading) {
    return <Loading />;
  }

  if (!charity?.isApproved) {
    window.location.assign(config.charityHomeUrl);
  }

  return <>{children}</>;
};

export { CharityAuthoriser };
