import { matchPath } from 'react-router';

import { PageType } from '../../types/graphql';
import { PathInfo } from '../context/PathInfo';
import { CustomError } from '../components/ErrorBoundary/errors';

type AdminRouteParams = {
  prefix: string;
  shortName?: string;
};

const PREFIX_TO_TYPE: Record<string, PageType> = {
  crowdfunding: PageType.Crowdfunding,
  page: PageType.OnePage,
  fundraising: PageType.OnePage,
  campaign: PageType.Campaign,
};

export const parsePath = (currentPath: string): PathInfo => {
  const match = matchPath<AdminRouteParams>(currentPath, {
    path: ['/:prefix/:shortName/admin', '/:prefix/admin'],
  });

  const pageType = match?.params?.prefix
    ? PREFIX_TO_TYPE[match?.params?.prefix]
    : null;

  if (!match || (!pageType && pageType !== PageType.Campaign)) {
    throw new CustomError({ message: 'Page not found', code: 404 });
  }

  const adminBasePath = match.url.replace(/\/$/, '');
  return {
    pageType,
    adminBasePath,
    pageSlug: getPageSlug(
      pageType,
      match.params.prefix,
      match?.params?.shortName
    ),
    pageBasePath: adminBasePath.replace(/\/admin$/, ''),
  };
};

const getPageSlug = (
  pageType: PageType,
  prefix: string,
  shortName?: string
) => {
  switch (true) {
    case pageType === PageType.Campaign:
      return '';
    case pageType === PageType.OnePage && Boolean(shortName):
      return `${prefix}/${shortName}`;
    case pageType === PageType.Crowdfunding && Boolean(shortName):
      return shortName!;
    default:
      throw new CustomError({
        message: `Unknown page type: ${pageType}`,
        code: 500,
      });
  }
};

export default parsePath;
