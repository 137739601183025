import { Page, PageStatus } from '../../../types/graphql';
import { campaign as baseCampaign } from './campaign';

interface Options {
  status?: PageStatus;
  cursor?: number;
  numberOfPages?: number;
  title?: string;
  hasNextPage?: boolean;
  withRef?: boolean;
  campaign?: RecursivePartial<Page>;
}

export const search = ({
  status = PageStatus.Active,
  cursor = 0,
  numberOfPages = 6,
  title = 'London Marathon',
  hasNextPage = true,
  campaign,
  withRef,
}: Options) => ({
  __typename: 'SearchConnection',
  edges: [...Array(numberOfPages)].map((_, index) => {
    const id = `${status}-${cursor}-${index}`;

    return {
      __typename: 'SearchEdge',
      node: withRef
        ? { __ref: `Page:{"legacyId":"${id}"}` }
        : baseCampaign({ ...campaign, id, title, status }),
    };
  }),
  pageInfo: {
    __typename: 'PageInfo',
    hasNextPage,
    endCursor: cursor + 1,
  },
});
