import React, { FunctionComponent, createContext, useContext } from 'react';

import Loading from '@justgiving/loading';

import {
  GetLoggedInPageOwnerQuery,
  GetPageOwnerQuery,
  PageType,
} from '../../../types/graphql';
import auth, { AuthWithHelpers, redirectToSSO } from '../../auth';
import {
  CustomError,
  RedirectToLoginError,
} from '../../components/ErrorBoundary/errors';
import config from '../../config';
import { usePageQuery } from '../../hooks/usePageQuery';
import {
  GetLoggedInPageOwner,
  GetPageOwner,
} from '../../queries/GetPageOwner.gql';
import { usePathInfo } from '../PathInfo';

const AuthContext = createContext<AuthWithHelpers | undefined>(undefined);
AuthContext.displayName = 'AuthContext';

export enum Permissions {
  IsPageOwner,
  IsCampaignAdmin,
}

interface AuthProviderProps {
  permissions: Permissions[];
}

function isLoggedInQuery(query: any): query is GetLoggedInPageOwnerQuery {
  return query?.viewer;
}

export const useIsPageOwner = () => {
  const { pageType: type } = usePathInfo();
  const { data, ...rest } = usePageQuery<
    GetPageOwnerQuery | GetLoggedInPageOwnerQuery
  >(type === PageType.OnePage ? GetLoggedInPageOwner : GetPageOwner);

  const isPageOwner = () => {
    const userId = auth.getAccessToken()?.guid;
    const page = isLoggedInQuery(data) ? data?.viewer?.page : data?.page;
    const ownerId = page?.owner?.legacyId || page?.owner?.id;

    if (!userId || !ownerId || userId !== ownerId) {
      throw new CustomError({ message: 'Page not found', code: 404 });
    }
  };

  return [isPageOwner, { data, ...rest }] as const;
};

const isCampaignAdmin = () => {
  const isCharity = auth.isCharity();
  const isCampaignAdmin =
    auth.hasGroupRole('charity', 'admin') ||
    auth.hasGroupRole('charity', 'fundraising');

  if (!isCharity) {
    throw new RedirectToLoginError({
      loginUrl: `${config.charitySSOUrl}${encodeURIComponent(
        window.location.href
      )}`,
    });
  }

  if (!isCampaignAdmin) {
    throw new CustomError({
      message: 'User does not have the correct permissions',
      code: 500,
    });
  }
};

export const AuthProvider: FunctionComponent<AuthProviderProps> = ({
  children,
  permissions,
}) => {
  const [isPageOwner, { loading }] = useIsPageOwner();

  if (!auth.isLoggedIn()) {
    redirectToSSO();
    return <Loading />;
  }

  if (loading) {
    return <Loading />;
  }

  if (auth.isStaff()) {
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
  }

  const checkMap = {
    [Permissions.IsPageOwner]: isPageOwner,
    [Permissions.IsCampaignAdmin]: isCampaignAdmin,
  };

  permissions.map((authCheck) => {
    return checkMap[authCheck]();
  });

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};
