import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { FieldMergeFunction } from '@apollo/client/cache/inmemory/policies';

export const buildApolloCache = () =>
  new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          page: {
            read(cachedPage, { args, toReference, ...rest }) {
              if (!args?.id) return cachedPage;

              return toReference({
                __typename: 'Page',
                legacyId: args?.id,
              });
            },
          },
          search: search(),
        },
      },
      Livestream: {
        keyFields: [],
      },
      Page: {
        keyFields: ['legacyId'],
        fields: {
          offlineDonations: relayStylePagination(),
          donations: relayStylePagination(),
          donationSummary: {
            merge: true,
          },
        },
      },
      Charity: {
        fields: {
          children: relayStylePagination(['statuses', 'productTypes']),
        },
      },
      User: {
        fields: {
          thirdPartyApplications: {
            merge: true,
          },
        },
      },
    },
  });

function search() {
  const relayFieldPolicy = relayStylePagination([
    ['query'],
    ['index'],
    ['filter', ['page', ['status']]],
    ['sort', ['field']],
    ['sort', ['direction']],
  ]);

  // relayStylePagination expects pagination args to be available at the top level
  // of the args object. Search wraps pagination args in an object so we need to feed
  // the correct pagination args into the merge function.
  const merge: FieldMergeFunction = (existing, incoming, options) =>
    typeof relayFieldPolicy.merge === 'function'
      ? relayFieldPolicy.merge(existing, incoming, {
          ...options,
          args: {
            ...options.args,
            ...options.args?.pagination,
          },
        })
      : relayFieldPolicy.merge;

  return {
    ...relayFieldPolicy,
    merge,
  };
}
