import { initReactI18next } from 'react-i18next';

import backend from '@justgiving/i18next-dynamic-import-backend';
import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';

import config from './config';

export const initI18n = async () =>
  i18n
    .use(initReactI18next)
    .use(
      backend(
        async (lang, namespace) => {
          try {
            return await import(
              /* webpackChunkName: "lang/[request]" */ `../lang/${lang}/${namespace}.json5`
            );
          } catch (error) {
            console.error(error);
          }
        },
        { loadDefault: true }
      )
    )
    .use(LngDetector)
    .init({
      ns: [
        'activity',
        'campaign.common',
        'common',
        'donations',
        'fitness',
        'fundraising.edit',
        'occasion.birthday',
        'occasion.common',
        'occasion.in_memory',
        'occasion.other',
        'occasion.wedding',
        'occasion',
        'settings',
      ],
      defaultNS: 'common',
      supportedLngs: ['en', 'en-GB', 'en-US'],
      fallbackLng: 'en',
      debug: config.i18nNextDebug,
      interpolation: {
        escapeValue: false,
      },
      detection: {
        order: ['querystring', 'navigator'],
        lookupQuerystring: 'locale',
      },
    });
