export function getChromeHeaderVariant(url: URL) {
  const type = url.pathname.split('/')[1];

  switch (type.toLowerCase()) {
    case 'campaign':
      return 'charity';
    case 'fundraising':
    case 'page':
    default:
      return 'consumer-nomenu';
  }
}
