import React from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';

import { ApolloProvider } from '@apollo/client';
import { loadClient as loadChrome } from '@justgiving/chrome';
import { init } from '@sentry/react';
import { Replay } from '@sentry/replay';
import { BrowserTracing } from '@sentry/tracing';

import config from './config';
import { client } from './graphqlClient';
import { initI18n } from './i18next';
import App from './roots/App/App';
import { isTestQueryString } from './utils';
import { handleTopLevelRedirects } from './utils/redirects';
import { getChromeHeaderVariant } from './utils/chrome';

init({
  dsn: config.sentryDsn,
  integrations: [
    new BrowserTracing(),
    new Replay({
      // Capture 10% of all sessions
      sessionSampleRate: 0.1,
      // Of the remaining 90% of sessions, if an error happens start capturing
      errorSampleRate: 1,
      maskAllText: false,
    }),
  ],
  tracesSampleRate: 0.1,
  environment: config.env,
  allowUrls: [/justgiving\.com/, /jg-cdn\.com/],
  ignoreErrors: [
    /not found/i,
    /must be logged in/i,
    /CookieConsent/,
    /Error: refreshAccessToken response not ok: 422, Unprocessable Entity/,
  ],
});

async function prepare() {
  if (!isTestQueryString('msw', 'true')) return;

  const { worker } = require('./mocks/browser');

  return worker.start({
    serviceWorker: {
      url: `${config.pathPrefix}mockServiceWorker.js`,
      scope: config.pathPrefix.slice(0, -1),
    },
  });
}

const redirectUrl = handleTopLevelRedirects(window.location.pathname);

if (redirectUrl) {
  window.location.href = redirectUrl;
} else {
  loadChrome({
    headerVariant: getChromeHeaderVariant(new URL(window.location.href)),
    footerVariant: 'slim',
  });

  prepare()
    .then(initI18n)
    .then(() => {
      ReactDOM.render(
        <React.StrictMode>
          <Toaster position="bottom-center" />
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </React.StrictMode>,
        document.getElementById('app')
      );
    });
}
