import React, { Component, ReactNode } from 'react';

import Loading from '@justgiving/loading';

import errorHandler from '../../utils/errorHandler';
import ErrorPage from '../ErrorPage/ErrorPage';
import { RedirectToLoginError, isRedirectToLoginError } from './errors';

interface ErrorBoundaryState {
  isRedirecting: boolean;
  hasError: boolean;
  error?: Error;
}

interface ErrorBoundaryProps {
  children: ReactNode;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      isRedirecting: false,
      hasError: false,
      error: undefined,
    };
  }

  static getDerivedStateFromError(error: unknown) {
    switch (true) {
      case error instanceof RedirectToLoginError:
        return { isRedirecting: true, error };
      default:
        return { hasError: true, error };
    }
  }

  componentDidCatch(error: unknown) {
    if (isRedirectToLoginError(error)) {
      goToUrl(error.loginUrl);
    } else {
      errorHandler(error);
    }
  }

  render() {
    if (this.state.isRedirecting) {
      return <Loading />;
    }
    if (this.state.hasError) {
      return <ErrorPage error={this.state.error} />;
    }
    return this.props.children;
  }
}

export function goToUrl(url: string) {
  window.location.assign(url);
}
