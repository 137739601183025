import {
  PageStatus,
  ProductType,
  SearchSortField,
  SortBy,
} from '../../../types/graphql';

export class RedirectToLoginError extends Error {
  loginUrl: string;

  constructor(props: { loginUrl: string }) {
    super();

    this.loginUrl = props.loginUrl;
  }
}

export class CustomError extends Error {
  message: string = '';
  code: number;

  constructor(props: { message: string; code: number }) {
    super();

    this.message = props.message;
    this.code = props.code;
  }
}

export function isRedirectToLoginError(
  error?: unknown | Error | RedirectToLoginError
): error is RedirectToLoginError {
  return error instanceof RedirectToLoginError;
}

export function isCustomError(
  error?: Error | CustomError
): error is CustomError {
  return error instanceof CustomError;
}

export class UnknownPageStatusError extends CustomError {
  constructor(props: { pageStatus?: PageStatus }) {
    super({
      message: `Page status is not known; given status ${props.pageStatus} is not valid; must be one of "${PageStatus.Completed}", "${PageStatus.Active}", "${PageStatus.Draft}", "${PageStatus.Scheduled}"`,
      code: 500,
    });
  }
}

export class UnknownProductTypeError extends CustomError {
  constructor(props: { productType?: ProductType }) {
    super({
      message: `Product type is not known; ${props.productType} is not valid; must be one of "${ProductType.Campaign}", "${ProductType.Sitebuilder}"`,
      code: 500,
    });
  }
}

export class UnknownSortFieldError extends CustomError {
  constructor(props: { field?: string }) {
    super({
      message: `Sort field is not known; ${props.field} is not valid; must be one of "${SearchSortField.CreationDate}"`,
      code: 500,
    });
  }
}

export class UnknownSortDirectionError extends CustomError {
  constructor(props: { direction?: string }) {
    super({
      message: `Sort direction is not known; ${props.direction} is not valid; must be one of "${SortBy.Ascending}", "${SortBy.Descending}"`,
      code: 500,
    });
  }
}
