export function gotoUrl(url: string) {
  window.location.assign(url);
}

export function isTestQueryString(
  queryString: string,
  lowerCasedQueryStringValue: string
) {
  const params = new URLSearchParams(window.location.search);
  return params.get(queryString)?.toLowerCase() === lowerCasedQueryStringValue;
}
