import {
  OptimizelyProvider as SdkProvider,
  createInstance,
} from '@optimizely/react-sdk';
import config from '../../config';
import React, { ReactNode } from 'react';

const optimizely = createInstance({
  sdkKey: config.optimizelySdkKey,
});

export const OptimizelyProvider = ({ children }: { children: ReactNode }) => {
  return <SdkProvider optimizely={optimizely}>{children}</SdkProvider>;
};
