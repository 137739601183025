import { Page, PageStatus } from '../../../types/graphql';
import {
  addDays,
  endOfYesterday,
  isBefore,
  startOfToday,
  subDays,
} from 'date-fns';

export const campaign = ({
  id,
  title = 'London Marathon',
  status = PageStatus.Active,
  campaign,
  fundraisingSettingsEnabled = true,
}: {
  id: string;
  title?: string;
  status?: PageStatus;
  campaign?: RecursivePartial<Page>;
  fundraisingSettingsEnabled?: boolean;
}) => {
  return {
    __typename: 'Page',
    ...getDates(status),
    id,
    legacyId: id,
    slug: 'campaign/mock-slug',
    status,
    title: `${title}-${id}`,
    logo: 'https://images.staging.justgiving.com/image/f60c8ab1-9876-4b44-be90-aa7b16f0d665.jpg',
    url: 'https://www.staging.justgiving.com/campaign/mock-campaign',
    summary: 'mock summary',
    donationSummary: {
      __typename: 'DonationSummary',
      totalAmount: {
        __typename: 'MonetaryAmount',
        value: 10000,
        currencyCode: 'GBP',
      },
      offlineAmount: {
        __typename: 'MonetaryAmount',
        value: 80000,
      },
      onlineAmount: { __typename: 'MonetaryAmount', value: 0 },
    },
    targetWithCurrency: {
      __typename: 'MonetaryAmount',
      value: 20000,
      currencyCode: 'GBP',
    },
    cover: {
      __typename: 'ImageMedia',
      url: 'https://images.staging.justgiving.com/image/4be65b3e-1ccf-4ff9-8340-74281b87ac96.jpg',
    },
    thumbnailImage:
      'https://images.justgiving.com/image/2ec1a213-7792-42e5-8ac3-0cf86036854d.jpg',
    offlineDonations: {
      edges: [
        {
          node: {
            amount: 800,
            currency: 'GBP',
            dateCreated: '2023-03-06T09:39:00.994Z',
            displayName: 'Campaign admin offline donation',
            id: 'T2ZmbGluZURvbmF0aW9uOjM3ZTBiNjcwLTZhMGQtNDllMS1iN2FhLWFlOTYxYjk5NmVhZQ==',
            legacyId: '37e0b670-6a0d-49e1-b7aa-ae961b996eae',
            message: null,
            __typename: 'OfflineDonation',
          },
          __typename: 'OfflineDonationEdge',
        },
      ],
      totalCount: 1,
      __typename: 'OfflineDonationConnection',
    },
    fundraisingSettings: {
      isEnabled: fundraisingSettingsEnabled,
      isTargetHidden: false,
    },
    relationships: {
      children: { totalCount: 0 },
      events: [],
      beneficiaries: {
        nodes: [
          {
            id: 'Q2hhcml0eTo1N2I2NmU2NC1kMGNhLTQzZWMtOWZlNy02YzFmNTA3YmZjOTI=',
            externalId: 2050,
            displayName: 'The Demo Charity',
            description:
              'The thing... the thing... with the thing and the thing',
            logo: 'https://images.staging.justgiving.com/image/d4c29b3b-7a9b-47f7-a9df-1353364564d1.jpg',
            registrationNumber: 'HMRC Registered',
            profilePageUrl: 'https://www.staging.justgiving.com/TheDemoCharity',
            countryCode: 'GB',
            impactStatement: 'We do this to help them People and those people3',
            slug: 'TheDemoCharity',
            __typename: 'Charity',
          },
        ],
        __typename: 'BeneficiaryConnection',
      },
    },
    branding: {
      headerImage:
        'https://images.staging.justgiving.com/image/36d4a93d-6cfa-4b4b-a5c2-d436803d7ec1.jpg',
      logo: 'https://images.staging.justgiving.com/image/f60c8ab1-9876-4b44-be90-aa7b16f0d665.jpg',
      backgroundImage: '',
      backgroundColor: '',
      primaryColor: '#653954',
      __typename: 'Branding',
    },
    ...campaign,
  };
};

export function getDates(pageStatus: PageStatus) {
  switch (pageStatus) {
    case PageStatus.Active:
      return {
        launchDate: startOfToday(),
        endDate: addDays(startOfToday(), 10),
      };
    case PageStatus.Scheduled:
      return {
        launchDate: addDays(startOfToday(), 5),
        endDate: addDays(startOfToday(), 10),
      };
    case PageStatus.Completed:
      return {
        launchDate: subDays(startOfToday(), 5),
        endDate: endOfYesterday(),
      };
    case PageStatus.Draft:
    default:
      return { launchDate: null, endDate: null };
  }
}

export function getStatus(expiryDate: Date) {
  switch (true) {
    case isBefore(expiryDate, startOfToday()):
      return PageStatus.Completed;
    default:
      return PageStatus.Active;
  }
}
