import {
  GetFrpEditPageFormQuery,
  OccasionType,
  OwnerType,
  Page,
  PageStatus,
  PageType,
  StoryType,
  ThankYouMessageType,
} from '../../../types/graphql';

export const page: Page = {
  __typename: 'Page',
  id: 'page-node-id',
  legacyId: 'page-id',
  externalId: 'external-id',
  title: 'Page title',
  type: PageType.OnePage,
  status: PageStatus.Active,
  endDate: '2020-06-01T11:00:00.000Z',
  url: 'https://www.justgiving.com/page/page-slug',
  supportsGiftAid: true,
  slug: 'page-slug',
  owner: {
    id: 'owner-node-id',
    legacyId: 'owner-id',
    name: 'Owner Name',
  },
  fundraisingSettings: {
    pageId: 'page-id',
    defaults: {},
    thankYouMessages: [
      {
        type: ThankYouMessageType.Donation,
        message: 'Thank you for your donation',
      },
    ],
  },
  product: {
    name: 'fundraising',
    allowedDonationMethods: [],
    allowedDonationTypes: [],
    allowedPointsOfView: [],
    features: {},
    ownerType: OwnerType.Individual,
  },
  relationships: null,
};

type GetFrpEditFormPage = NonNullable<
  NonNullable<GetFrpEditPageFormQuery['viewer']>['page']
>;

export const PAGE_WITHOUT_EVENT_SLUG = 'page/page-without-event';
export const PROXY_PAGE_SLUG = 'fundraising/page-slug';
export const PAGE_WITH_EVENT_NOT_VISIBLE_SLUG =
  'page/page-with-event-not-visible';

export const getFrpEditPage = (
  page?: Partial<GetFrpEditFormPage>
): GetFrpEditFormPage => ({
  id: 'page-node-id',
  legacyId: 'page-id',
  title: "Ben's MASSIVE Sweet 16th",
  slug: 'page/page-slug',
  storyWithType: {
    __typename: 'Story',
    type: StoryType.Plaintext,
    content: 'test story',
  },
  type: PageType.OnePage,
  url: `https://staging.justgiving.com/page/page-slug`,
  target: {
    __typename: 'MonetaryAmount',
    value: 100000,
    currencyCode: 'GBP',
  },
  endDate: '2020-06-01T11:00:00.000Z',
  cover: null,
  gallery: null,
  occasion: {
    __typename: 'Occasion',
    type: OccasionType.Birthday,
    date: '2020-06-01T11:00:00.000Z',
    names: ['Birthday Boi'],
    description: null,
    relationshipToPageCreator: null,
  },
  activity: {
    __typename: 'Activity',
    name: 'Test Activity',
    date: '2020-06-01T11:00:00.000Z',
  },
  relationships: {
    __typename: 'PageRelationships',
    events: {
      __typename: 'EventConnection',
      nodes:
        page?.slug === PAGE_WITHOUT_EVENT_SLUG
          ? []
          : [
              {
                __typename: 'Event',
                id: 'event-id',
                fundraisersCount: 250000,
                endDate: new Date('2023, 02, 06').toISOString(),
                title: 'London Marathon',
                visible:
                  page?.slug === PAGE_WITH_EVENT_NOT_VISIBLE_SLUG
                    ? false
                    : true,
              },
            ],
    },
  },
  ...page,
});
