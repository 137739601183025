import { PageType } from '../../types/graphql';
export type TypeSlug = {
  typeToPass: PageType | undefined;
  slugToPass: string;
};
export const typeSlugToPass = (
  pageType: PageType,
  slug?: string | null
): TypeSlug => {
  return {
    typeToPass: [PageType.OnePage, PageType.Fundraising].includes(pageType)
      ? undefined
      : pageType,
    slugToPass:
      slug &&
      pageType === PageType.Fundraising &&
      !slug.startsWith('fundraising/')
        ? (slug = 'fundraising/' + slug)
        : slug ?? '',
  };
};
