import * as Sentry from '@sentry/react';

import config from '../config';

export default function errorHandler(error: unknown) {
  if (!(error instanceof Error)) return;

  Sentry.withScope((scope) => {
    scope.setExtras({ componentStack: error.stack });
    Sentry.captureException(error);
  });

  if (config.logErrorsToConsole) {
    console.group(error.message);
    console.error(error);
    console.error({ componentStack: error.stack });
    console.groupEnd();
  }
}
