import { OperationVariables, QueryHookOptions, useQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { Page } from '../../types/graphql';

import { usePathInfo } from '../hooks/usePathInfo';
import { typeSlugToPass } from '../utils/typeSlugManipulator';

export type WithoutPageQueryVariables<T> = Omit<T, 'slug' | 'type'>;
type WithPageQueryVariables<T> = T & Pick<Page, 'type' | 'slug'>;

export const usePageQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, WithoutPageQueryVariables<TVariables>>
) => {
  const { pageSlug: slug, pageType: type } = usePathInfo();
  const { typeToPass, slugToPass } = typeSlugToPass(type, slug);
  const { fetchMore, ...useQueryResult } = useQuery<
    TData,
    WithoutPageQueryVariables<TVariables>
  >(query, {
    ...options,
    variables: {
      ...options?.variables,
      slug: slugToPass,
      type: typeToPass,
    } as WithPageQueryVariables<TVariables>,
    skip: !slugToPass,
  });

  const pageQueryFetchMore = (
    variables: WithoutPageQueryVariables<TVariables>
  ) => {
    fetchMore({
      variables: {
        ...variables,
        slug: slugToPass,
        type: typeToPass,
      },
    });
  };
  return { fetchMore: pageQueryFetchMore, ...useQueryResult };
};
